import {
    BarLine,
    ButtonContainer,
    Container,
    InputWrap,
    TotalResultText,
} from './style';
import {
    Counter,
    CsvDownloader,
    CustomButton,
    Loader,
    ModalComp,
    PdfDownloader,
} from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { UserTableHeader } from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { MdOutlinePersonAddDisabled, MdBlockFlipped } from 'react-icons/md';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as UserIcon } from '../../common/images/people_icon.svg';
import { SelectComp } from '../../common/component/Ui/selectComp';
import {
    useGetAllUserQuery,
    useUserStatsQuery,
    useUpdateUserRoleMutation,
    useUpdateUserStatusMutation,
    useGetSingleUserQuery,
} from '../../store/userApi';
import { Controller, useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';

export const Users = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [newArray, setNewArray] = useState<IUser[] | any>();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };
    const {
        data: userList,
        isLoading,
        isFetching,
    } = useGetAllUserQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });
    const { data: userStats } = useUserStatsQuery();
    const [userId, setUserId] = useState('');
    const { data: singleUserList } = useGetSingleUserQuery(userId);
    const [accountStatusModal, setAccountStatusModal] = useState(false);
    const [userRoleModal, setUserRoleModal] = useState(false);
    const [updateUserRole, { isLoading: isLoadingUserRole }] =
        useUpdateUserRoleMutation();
    const [updateUserStatus, { isLoading: isLoadingUserStatus }] =
        useUpdateUserStatusMutation();

    useEffect(() => {
        setTotalRows(userList?.pagination?.totalDocumentCount as number);
    }, [userList?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!userList?.data?.length) return; //when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const Items = [
        {
            name: ' View User',
            icon: <BsEye size={15} />,
            link: 'view',
            id: '_id',
        },
        {
            name: ' Ban User',
            icon: <MdBlockFlipped size={15} />,
            OnClick: (row: IUser) => {
                setUserId(row._id);
                setAccountStatusModal(true);
            },
            link: '',
            id: 'id',
        },
    ];

    // To implement search and dropdown input
    useEffect(() => {
        if (searchTerm === '' && dropDownTerm === '') {
            setNewArray(userList?.data);
        }
        if (dropDownTerm !== '') {
            let filteredItems = userList?.data?.filter((item: IUser) => {
                return item?.userType
                    .toLowerCase()
                    .includes(dropDownTerm.toLowerCase());
            });
            setNewArray(filteredItems);
        }
        if (searchTerm !== '') {
            let searchItems = userList?.data?.filter((item: IUser) => {
                return (
                    item?.firstName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase()) ||
                    item?.lastName
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase())
                );
            });
            setNewArray(searchItems);
        }
    }, [userList, searchTerm, dropDownTerm]);

    //change accout status
    const editUserStatus = useForm({
        defaultValues: {
            newData: { accountStatus: '' },
        },
    });
    const { handleSubmit, control, setValue } = editUserStatus;

    useEffect(() => {
        if (singleUserList?.data?._id) {
            setValue(
                'newData',
                {
                    accountStatus: singleUserList?.data?.accountStatus,
                },
                { shouldValidate: true },
            );
        }
    }, [singleUserList, setValue]);

    const onHandleChangeAccountStatus = (value: {
        newData: { accountStatus: string };
    }) => {
        let payload = {
            userId: userId,
            accountStatus: value.newData.accountStatus,
        };
        console.log('statuspayload', payload);

        updateUserStatus(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setAccountStatusModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //change user role
    const editUserRole = useForm({
        defaultValues: {
            newData: {
                userType: '',
            },
        },
    });
    const {
        handleSubmit: handleSubmitUserRole,
        control: userRoleControl,
        setValue: setUserRole,
    } = editUserRole;

    useEffect(() => {
        if (singleUserList?.data?._id) {
            setUserRole(
                'newData',
                {
                    userType: singleUserList?.data?.userType,
                },
                { shouldValidate: true },
            );
        }
    }, [singleUserList, setUserRole]);

    const onHandleChangeUserRole = (value: {
        newData: { userType: string };
    }) => {
        let payload = {
            userId: userId,
            userType: value?.newData?.userType,
        };
        console.log(payload, 'typepayload');

        updateUserRole(payload)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setUserRoleModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    //add the header like this and just send array of Data to CsvDownloader
    const headersCsv = [
        { label: 'First Name', key: 'firstName' },
        {
            label: 'Last Name',
            key: 'lastName',
        },
        {
            label: 'Email',
            key: 'email',
        },
        {
            label: 'User Type',
            key: 'userType',
        },
    ];
    //add the header like this and just send array of Data to pDFDownloader
    const headersPdf = [
        { header: 'First Name', dataKey: 'firstName' },
        {
            header: 'Last Name',
            dataKey: 'lastName',
        },
        {
            header: 'Email',
            dataKey: 'email',
        },
        {
            header: 'User type',
            dataKey: 'userType',
        },
    ];

    return (
        <Container>
            {isLoading && <Loader />}
            <Header>Users</Header>
            <Grid>
                <Counter
                    counter_title="Total Users"
                    count={userStats?.data?.totalUsers || '0'}
                    iconFill="darkBlue"
                    iconBG="blue"
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Active Users"
                    count={userStats?.data?.activeUsers || '0'}
                    iconFill="red"
                    iconBG="red"
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Partners"
                    count={userStats?.data?.partnerUsers || '0'}
                    iconFill="green"
                    iconBG="green"
                >
                    <UserIcon />
                </Counter>
                <Counter
                    counter_title="Merchants"
                    count={userStats?.data?.merchantUsers || '0'}
                >
                    <UserIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={9}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <Col
                        xs={10}
                        sm={6}
                        className="d-flex align-items-center my-3"
                    >
                        <SelectComp
                            selectText="All users"
                            arrayComp={['Partner', 'User', 'Merchant', 'Admin']}
                            onChange={(e: any) =>
                                setDropDownTerm(e.target.value)
                            }
                        />
                        <TotalResultText>{`${
                            userList?.data?.length || 0
                        } result`}</TotalResultText>
                    </Col>
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <ButtonContainer>
                        <PdfDownloader
                            fileName={"USER'S RECORD"}
                            {...{ columns: headersPdf, body: userList?.data }}
                        />
                        <BarLine />
                        <CsvDownloader
                            headers={headersCsv}
                            data={userList?.data}
                        />
                    </ButtonContainer>
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={UserTableHeader(Items)}
                    data={newArray}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>

            {/* change account status */}
            <ModalComp
                show={accountStatusModal}
                handleClose={() => setAccountStatusModal(false)}
                centered
                close={true}
                modalTitle="User status"
                paragraph="Fill in the  the forms to change user status"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form
                            onSubmit={handleSubmit(onHandleChangeAccountStatus)}
                        >
                            <Controller
                                name="newData.accountStatus"
                                control={control}
                                rules={{
                                    required: 'acount status is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.accountStatus?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            arrayComp={[
                                                'suspended',
                                                'inactive',
                                                'disabled',
                                            ]}
                                            name="serviceChargeOnPartnerCharge"
                                            defaultValue={
                                                singleUserList?.data
                                                    ?.accountStatus
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isLoadingUserStatus
                                            ? 'Save changes'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />

            {/* change user type */}
            <ModalComp
                show={userRoleModal}
                handleClose={() => setUserRoleModal(false)}
                centered
                close={true}
                modalTitle="User type"
                paragraph="Fill in the  the forms to change user type"
                modalBody={
                    <div className="py-4 px-lg-4 ">
                        <form
                            onSubmit={handleSubmitUserRole(
                                onHandleChangeUserRole,
                            )}
                        >
                            <Controller
                                name="newData.userType"
                                control={userRoleControl}
                                rules={{
                                    required: 'user type is required',
                                }}
                                render={({
                                    field: { value, onChange },
                                    formState: { errors },
                                }) => {
                                    const errorMessage =
                                        errors?.newData?.userType?.message;
                                    return (
                                        <SelectComp
                                            labelText="Status"
                                            arrayComp={[
                                                'user',
                                                'admin',
                                                'merchant',
                                                'partner',
                                            ]}
                                            name="serviceChargeOnPartnerCharge"
                                            defaultValue={
                                                singleUserList?.data?.userType
                                            }
                                            {...{
                                                value,
                                                onChange,
                                                errors: [errorMessage],
                                            }}
                                        />
                                    );
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        !isLoadingUserRole
                                            ? 'Save changes'
                                            : 'Loading...'
                                    }
                                />
                            </div>
                        </form>
                    </div>
                }
            />
        </Container>
    );
};
