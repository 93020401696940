import { Container, InputWrap } from '../users/style';
import { Counter, Loader, TextEditor } from '../../common/component';
import { Grid, Header } from '../dashboard/styles';
import { TableCompData } from '../../common/component/table';
import { ContentHeader } from '../../common/utils/dataUtils';
import { BsEye } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import { ContainerComp } from '../../common/component/Ui/containerComp';
import { Col, FormControl } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { CustomButton } from '../../common/component/button';
import { ModalComp } from '../../common/component/Ui/modalComp';
import SearchIcon from '../../common/images/search_icon.svg';
import { ReactComponent as CareerIcon } from '../../common/images/career_icon.svg';
import { IJob } from '../../store/jobAPi/interface';
import { showSuccessToast, showErrorToast } from '../../common/utils/toast';
import { Controller, useForm } from 'react-hook-form';
import { Flexbox2 } from '../currencyPair/style';
import {
    useContentStatsQuery,
    useCreateContentMutation,
    useDeleteContentMutation,
    useGetAllContentQuery,
    useGetAllSubTopicQuery,
    useGetAllTopicsQuery,
} from '../../store/topicApi';
import { ITopic } from '../../store/topicApi/interface';
import CustomSearchDropDown from '../../common/component/Ui/customSearch';

interface IContentValues {
    title: string;
    description: string;
    topic: string;
    subtopic: string;
}

export const Content = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropDownTerm, setDropDownTerm] = useState('');
    const [newArray, setNewArray] = useState<ITopic[] | any>();
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(100);
    const paginate = {
        perPage: rowsPerPage,
        currentPage: currentPage,
    };

    const { data: allTopics } = useGetAllTopicsQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });

    const { data: allSubTopics } = useGetAllSubTopicQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });

    const {
        data: allContent,
        isLoading,
        isFetching,
    } = useGetAllContentQuery(paginate, {
        refetchOnMountOrArgChange: true,
    });

    const [createContent, { isLoading: isAddContent }] =
        useCreateContentMutation();
    const [deleteContent, { isLoading: isDeleting }] =
        useDeleteContentMutation();
    const { data: contentStats } = useContentStatsQuery();
    const [contentId, setContentId] = useState<any>('');
    const [title, setTitle] = useState<any>();
    const [desc, setDesc] = useState<any>();
    const [topicApiId, setTopicApiId] = useState<any>('');
    const [subTopicApiId, setSubTopicApiId] = useState<any>('');
    useEffect(() => {
        setTotalRows(allContent?.pagination?.totalDocumentCount as number);
    }, [allContent?.pagination?.totalDocumentCount]);
    const handlePageChange = (page: any) => setCurrentPage(page);
    const handleRowsPerPageChange = (newRowsPerPage: any) => {
        if (!allContent?.data?.length) return;
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    const Items = [
        {
            name: ' View Content',
            icon: <BsEye size={15} />,
            link: 'view',
            id: '_id',
        },
        {
            name: ' Delete Content',
            icon: <AiOutlineDelete size={15} />,
            OnClick: (data: any) => {
                setContentId(data?._id);
                setDeleteModal(true);
            },
        },
    ];

    // To implement search and dropdown input
    useEffect(() => {
        if (searchTerm === '' && dropDownTerm === '') {
            setNewArray(allContent?.data);
        }
        if (dropDownTerm !== '') {
            let filteredItems = allContent?.data?.filter((item: IJob) => {
                return item?.schedule
                    .toLowerCase()
                    .includes(dropDownTerm.toLowerCase());
            });
            setNewArray(filteredItems);
        }
        if (searchTerm !== '') {
            let searchItems = allContent?.data?.filter((item: IJob) => {
                return item?.title
                    ?.toLowerCase()
                    .includes(searchTerm?.toLowerCase());
            });
            setNewArray(searchItems);
        }
    }, [allContent, searchTerm, dropDownTerm]);

    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            title: '',
            description: '',
            topic: '',
            subtopic: '',
        },
    });

    const onHandleSubmit = (value: IContentValues) => {
        const payload = {
            topic: topicApiId?.value,
            subTopic: subTopicApiId?.value,
            content: title,
            description: desc,
            status: true,
        };
        createContent(payload)
            .unwrap()
            .then((result) => {
                console.log('Subtopic added successfully:', result);
                showSuccessToast(result?.message);
                setShowModal(false);
                reset();
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const onDeleteContent = (id: string) => {
        deleteContent(id)
            .unwrap()
            .then((result) => {
                showSuccessToast(result?.message);
                setDeleteModal(false);
            })
            .catch((error) => {
                showErrorToast(error?.data?.message);
            });
    };

    const cleanupTopics = (topicsInQuestion: any) => {
        if (topicsInQuestion) {
            const result = topicsInQuestion.map((topic: any) => {
                return { label: topic.title, value: topic?._id };
            });

            return result;
        }

        return [];
    };

    const cleanupSubTopics = (subTopicsInQuestion: any) => {
        if (subTopicsInQuestion) {
            const result = subTopicsInQuestion.map((subTopic: any) => {
                return { label: subTopic.title, value: subTopic?._id };
            });

            return result;
        }

        return [];
    };

    return (
        <Container>
            {isLoading || isDeleting ? <Loader /> : null}
            <Header>Content</Header>
            <Grid>
                <Counter
                    counter_title="Total Contents"
                    count={contentStats?.data?.totalContents || 0}
                    iconFill="blue"
                    iconBG="blue"
                >
                    <CareerIcon />
                </Counter>
                <Counter
                    counter_title="Inactive Contents"
                    count={contentStats?.data?.InActiveContents || 0}
                >
                    <CareerIcon />
                </Counter>
                <Counter
                    counter_title="Active Contents"
                    count={contentStats?.data?.activeContents || 0}
                    iconFill="green"
                    iconBG="green"
                >
                    <CareerIcon />
                </Counter>
                <Counter
                    counter_title="Deleted Contents"
                    count={contentStats?.data?.deletedContents || 0}
                    iconFill="red"
                    iconBG="red"
                >
                    <CareerIcon />
                </Counter>
            </Grid>
            <div className="d-xl-flex align-items-center justify-content-between mt-5 mb-3">
                <Col
                    sm={12}
                    xl={8}
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                >
                    <InputWrap>
                        <img src={SearchIcon} alt="" />
                        <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Search"
                            className="border-start-0 border-bottom-0 border-top-0"
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputWrap>
                </Col>
                <div className="d-flex align-items-center justify-content-center justify-content-sm-end my-3">
                    <CustomButton
                        text="Create Content "
                        onClick={() => setShowModal(true)}
                    />
                </div>
            </div>

            <ContainerComp>
                <TableCompData
                    columns={ContentHeader(Items)}
                    data={newArray}
                    progressPending={isFetching}
                    totalRows={totalRows}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                />
            </ContainerComp>
            <ModalComp
                show={showModal}
                handleClose={() => setShowModal(false)}
                centered
                close={true}
                size="lg"
                modalTitle="Create Content"
                paragraph="Fill in the form to create a Content"
                modalBody={
                    <form onSubmit={handleSubmit(onHandleSubmit)}>
                        <div className="py-1 px-lg-4 ">
                            <div className="mb-4">
                                <div>
                                    {' '}
                                    <Controller
                                        name="topic"
                                        control={control}
                                        rules={{
                                            required: '*topic is required',
                                            min: '8',
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors.topic?.message;
                                            setTopicApiId(value);
                                            return (
                                                <CustomSearchDropDown
                                                    isShowLabel
                                                    placeholder="Select..."
                                                    defaultOptions={cleanupTopics(
                                                        allTopics?.data,
                                                    )}
                                                    label="Select Topic"
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="my-4">
                                <div>
                                    {' '}
                                    <Controller
                                        name="subtopic"
                                        control={control}
                                        rules={{
                                            required: '*Subtopic is required',
                                            min: '8',
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            formState: { errors },
                                        }) => {
                                            const errorMessage =
                                                errors.topic?.message;
                                            setSubTopicApiId(value);
                                            return (
                                                <CustomSearchDropDown
                                                    isShowLabel
                                                    placeholder="Select..."
                                                    defaultOptions={cleanupSubTopics(
                                                        allSubTopics?.data,
                                                    )}
                                                    label="Select Subtopic"
                                                    {...{
                                                        value,
                                                        onChange,
                                                        errors: [errorMessage],
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="my-4">
                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: 'Content title is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.title?.message;
                                        setTitle(value);
                                        return (
                                            <TextEditor
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                                labelText="Content title"
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="my-4">
                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required:
                                            'Content description is required',
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        formState: { errors },
                                    }) => {
                                        const errorMessage =
                                            errors?.title?.message;
                                        setDesc(value);
                                        return (
                                            <TextEditor
                                                {...{
                                                    value,
                                                    onChange,
                                                    errors: [errorMessage],
                                                }}
                                                labelText="Content description"
                                            />
                                        );
                                    }}
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <CustomButton
                                    className="py-3 mt-4"
                                    text={
                                        isAddContent
                                            ? 'Loading...'
                                            : 'Create Content'
                                    }
                                />
                            </div>
                        </div>
                    </form>
                }
            />
            <ModalComp
                show={deleteModal}
                handleClose={() => setDeleteModal(false)}
                centered
                close={true}
                modalTitle="Delete Content"
                modalBody={
                    <Flexbox2>
                        <CustomButton
                            className="py-3 mt-4"
                            text={'Cancel'}
                            onClick={() => setDeleteModal(false)}
                        />
                        <CustomButton
                            className="py-3 mt-4"
                            text={!isDeleting ? 'Delete' : 'Loading...'}
                            onClick={() => onDeleteContent(contentId)}
                        />
                    </Flexbox2>
                }
            />
        </Container>
    );
};
